import { useCallback } from 'react';

import { DocumentStatus } from 'shared/components/formatters/document-status.formatter';
import { DateTimeFormatter } from 'shared/components/formatters/date-time.formatter';
import { DocumentType } from 'shared/components/formatters/document-type.formatter';
import { Translation } from 'shared/components/translation/translation.component';
import { LoadDocument } from 'shared/models/load-document.model';
import { Button } from 'shared/components/elements/elements.components';
import { DataLabelPair } from 'shared/components/formatters/data-label-pair.component';
import { ErrorBoundary } from 'shared/components/error-boundary/error-boundary.component';

interface Props {
  document: LoadDocument;
  loadNumber: number;
  onDownload: (document: LoadDocument) => any;
}

export const DocumentCard = ({document, loadNumber, ...props}: Props) => {
  const downloadDocument = useCallback(() => props.onDownload(document), [document, props.onDownload]);
  const documentName: string = `${loadNumber}-${document.id}${document.fileExtension}`;

  return (
    <div className="ns-card shadow space-outer-top-md">
      <ErrorBoundary className="space-outer-top-xl space-outer-bottom-xl space-outer-left-lg space-outer-right-lg">
        <DataLabelPair
          label="STATUS"
          data={
            <div>
              {document.isProcessingUpload()
                ? <Translation resource="PROCESSING"/>
                : <DocumentStatus status={document.status}/>
              }
            </div>
          }
        />
        <div className="flex flex-wrap">
          <DataLabelPair
            label="CREATED_DATE"
            className="space-outer-right-md space-outer-top-sm"
            data={<DateTimeFormatter date={document.createdDateTime}/>}
          />
          <DataLabelPair className="space-outer-right-md space-outer-top-sm" label="DOCUMENT_NAME" data={documentName}/>
          <DataLabelPair
            label="DOCUMENT_TYPE"
            className="space-outer-top-sm"
            data={<DocumentType value={document.type}/>}
          />
        </div>
        {document.canDownload() &&
        <Button
          type="button"
          className="js-btn-download-document space-outer-top-md hidden-print"
          id={`document-${document.id}`}
          btnPrimary
          onClick={downloadDocument}
          resource="DOWNLOAD"
        />
        }</ErrorBoundary>
    </div>
  );
};
