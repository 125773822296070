import { CardView, DataTable, GridView } from 'shared/components/data-table/data-table.lazy';
import { DatetimeUtil } from 'app/util/util';
import { DocumentCard } from './documents-table.card.component';
import { DocumentRepository } from 'app/repositories/document.repository';
import { DocumentRow } from './documents-table.row.component';
import { LazyBoundary } from 'shared/components/lazy/lazy-boundary.component';
import { LoadDocument } from 'shared/models/load-document.model';
import { saveAs } from 'file-saver';
import { Translation } from 'shared/components/translation/translation.component';
import { useCallback } from 'react';
import { useRepository } from 'app/hooks/ajax/use-repository.hook';
import { useStoredUser } from 'app/hooks/store/use-stored-user.hook';
import { useToastManager } from 'shared/components/toast/use-toasts.hook';

interface Props {
  documents: LoadDocument[];
  loadNumber: number;
}

export const DocumentsDataTable: React.FunctionComponent<Props> = ({ documents, loadNumber }) => {
  const repo = useRepository(DocumentRepository)
  const toasts = useToastManager();

  const downloadDocument = useCallback((doc: LoadDocument) => {
      return repo.downloadDocumentPayments(loadNumber, doc).subscribe(
        file => saveAs(file, `${loadNumber}-${doc.id}${doc.fileExtension}`),
        error => {
          toasts.error([<Translation resource="AN_UNKNOWN_ERROR_OCCURRED_TRYING_TO_MAKE_THE_REQUE" />]);
        }
      );
    }, [loadNumber]);

  // convert timestamps to users local time before displaying
  const user = useStoredUser();
  documents?.forEach(document => document.createdDateTime = DatetimeUtil.convertTimeFromCSTtoTimeZone(document.createdDateTime, user.properties.timeZone))

  const renderCard = useCallback((document: LoadDocument) =>
    <DocumentCard
      key={`${document.id}`}
      document={document}
      onDownload={downloadDocument}
      loadNumber={loadNumber} />,
    [downloadDocument, loadNumber]
  );

  const renderBodyRow = useCallback((document: LoadDocument, columns: number) =>
      <DocumentRow
        key={`${document.id}`}
        document={document}
        onDownload={downloadDocument}
        loadNumber={loadNumber}
        columns={columns} />,
    [downloadDocument]
  );

  return (
    <LazyBoundary>
      <DataTable items={documents}>
        <GridView
          scrollable
          headerRow={
            <tr>
              <th><Translation resource="DOCUMENT_TYPE" /></th>
              <th><Translation resource="DOCUMENT_NAME" /></th>
              <th><Translation resource="STATUS" /></th>
              <th><Translation resource="ACTIONS" /></th>
              <th><Translation resource="DATE_RECEIVED"/></th>
            </tr>
          }
          renderBodyRow={renderBodyRow}
        />
        <CardView renderCard={renderCard} />
      </DataTable>
    </LazyBoundary>
  );
};
