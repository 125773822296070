import React from 'react';

import { DocumentStatus as DocStatus } from 'shared/enums/document-status.enum';

export const DocumentStatusResourceKeysMap  = {
  [DocStatus.Unknown]: 'Unknown',
  [DocStatus.Deleted]: 'Deleted',
  [DocStatus.DuplicateDocument]: 'Duplicate',
  [DocStatus.Error]: 'Error',
  [DocStatus.Indexed]: 'Complete',
  [DocStatus.ProcessingUpload]: 'Uploading',
  [DocStatus.ReadyForBucketing]: 'Under Review',
  [DocStatus.ReadyForIndexing]: 'Under Review',
  [DocStatus.ReadyForAutoIndexing]: 'Processing'
};

interface Props {
  status: DocStatus;
}

export const DocumentStatus = ({status}: Props) =>
  <React.Fragment>
    {DocumentStatusResourceKeysMap[status]}
  </React.Fragment>;
