import { AreaContext } from 'shared/components/analytics/area.context';
import { useButtonClickStream } from 'shared/components/click-stream/use-button-clickstream.hook';
import { Button } from 'shared/components/elements/elements.components';
import { ErrorBoundary } from 'shared/components/error-boundary/error-boundary.component';
import { useHideModal } from 'shared/components/modal/hooks/use-hide-modal.hook';
import { useRegisterOnMount } from 'shared/components/modal/hooks/use-register-on-mount.hook';
import { useShowAndHideFunctionality } from 'shared/components/modal/hooks/use-show-and-hide-functionality.hook';
import { useVisibilityChangesSubject } from 'shared/components/modal/hooks/use-visibility-changes-subject.hook';
import { Translation } from 'shared/components/translation/translation.component';
import { ButtonType } from 'shared/models/click-stream/button-type.model';
import classNames from 'classnames';
import { FC, Fragment, useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { WindowSize } from '../azure-map/hooks/use-window-size.hook';
import { useModalState } from './hooks/use-modal-state.hook';

import './modal.component.scss';
import { ADD_DOCUMENT_MODAL } from '@pages/load-details/sections/documents/add-document/add-document.component';

export interface ModalProps {
  name: string;
  onHide?: () => any;
  onModalClose?: () => any; // unlike onHide, onClose is called only on X button click or click outside the modal.
  onShow?: () => any;
  onHideClick?: () => boolean;
  header?: (ResourceKey | ((context?) => JSX.Element));
  children?: any;
  footer?: ((context?) => JSX.Element) | JSX.Element[] | JSX.Element;
  disableClose?: boolean;
  disableClickingOffModalClose?: boolean; // Disable clicking outside the modal to close it.
  track?: boolean;
  dataContext?: string;
  isForm?: boolean;
  ariaDescribedBy?: string; // id of text element that describes the primary purpose/message of modal.
  closeBtnPrefix?: string; // close btn prefix. can be used to send unique button id on click event track.
  trackOutsideModalClick?: boolean;
  windowSize?: WindowSize; // fits to window if provided
  handleDiscardModal?: () => void;
}

export const Modal: FC<ModalProps> = ({
  name,
  disableClose,
  header,
  footer,
  track,
  dataContext,
  ariaDescribedBy,
  isForm,
  closeBtnPrefix,
  trackOutsideModalClick,
  onModalClose,
  windowSize,
  ...props
}) => {
  const [fitToWindowStyles, setFitToWindowStyles] = useState(null);
  const { visible, context } = useModalState(name);
  const visibilityChanges = useVisibilityChangesSubject(name);
  const hide = useHideModal(name, disableClose);
  const modalRef = useRef(null);

  const trackModalClose = useButtonClickStream(
    `${name}-outside-modal-area`,
    ButtonType.Button,
    trackOutsideModalClick,
  );

  useEffect(() => {
    if (windowSize?.height && windowSize?.width) {
      setFitToWindowStyles({
        height: `${windowSize.height}px`,
        width: `${windowSize.width}px`,
        margin: 0
      });
    } else if (fitToWindowStyles !== null) {
      setFitToWindowStyles(null);
    }
  }, [windowSize]);

  const renderChildren = useCallback(() => {
    const childContext = {
      ...context,
      visibilityChanges
    };
    return typeof props.children === 'function' ? props.children(childContext) : props.children;
  }, [props.children, visibilityChanges, context]);

  useShowAndHideFunctionality(name, disableClose, props.onShow, props.onHide, visibilityChanges, modalRef, onModalClose);
  useRegisterOnMount(name, hide, visibilityChanges);

  const headerValue = (typeof header === 'function') ? header(context) : <Translation resource={header} />;
  const footerValue = useMemo(() => typeof footer === 'function' ? footer(context) : footer, [footer, context]);

  const modalArea = !Boolean(context.type)
    ? `${name}-modal`
    : `${name}-${context.type.toLowerCase().replace('_', '-')}-modal`;

  const hideModal = (event) => {
    if (name === ADD_DOCUMENT_MODAL){
      props.handleDiscardModal();
    }else{
      if (props.disableClickingOffModalClose ||
       event.nativeEvent.target.closest('.modal-dialog')) {
        return;
      }
      if (event.target.id === 'popover-body') {
        return;
      }

      if (trackOutsideModalClick) {
        trackModalClose(null);
      }
      onModalClose && onModalClose();
      hide();
    }

  };

  return (
    <AreaContext.Provider value={{ area: modalArea }}>
      <div className={classNames('chr-modal', name)}>
        <div className={'modal-wrapper react' + (visible ? '' : ' hidden')}>
          <div className={'modal fade in'} id="basic_modal" onClick={hideModal}>
            <div
              className="modal-dialog"
              aria-labelledby={name + '-label'}
              role="dialog"
              aria-describedby={ariaDescribedBy}
              ref={modalRef}
              tabIndex={-1}
              aria-modal={true} // indicates to screen readers only content contained inside dialog should be accessible to user
              style={fitToWindowStyles}
              onClick={(event) => event.stopPropagation()}
            >
              <div className="modal-content">
                <ErrorBoundary className="space-outer-top-xxl space-outer-bottom-xxl space-outer-left-xl space-outer-right-xl">
                  <div className="modal-header">
                    {!disableClose &&
                      <Button
                        id={closeBtnPrefix ? `${closeBtnPrefix}-top-close-btn` : 'top-close-btn'}
                        data={dataContext}
                        className="close js-modal-close-button"
                        data-dismiss="modal"
                        aria-label="Close"
                        track={track}
                        onClick={() => {
                            if (name === ADD_DOCUMENT_MODAL) { props.handleDiscardModal(); }else{
                              if (props.onHideClick ? props.onHideClick() : true) {
                                onModalClose && onModalClose();
                              }
                              hide();
                          }
                        }}
                      >
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#555555" />
                        </svg>
                      </Button>}
                    <h2
                      id={name + '-label'}
                      className="modal-title space-inner-top-md"
                    >
                      {headerValue}
                    </h2>
                  </div>
                  {isForm
                    ? <form>
                      <div className="modal-body">{renderChildren()}</div>
                      <div className="modal-footer button-bay_mobile-responsive text-right">
                        {footerValue}
                      </div>
                    </form>
                    : <Fragment>
                      <div className="modal-body">
                        {renderChildren()}
                      </div>
                      {footerValue &&
                        <div className="modal-footer button-bay_mobile-responsive text-right">
                          {footerValue}
                        </div>
                      }
                    </Fragment>
                  }
                </ErrorBoundary>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={'modal-backdrop fade in' + (visible ? '' : ' hidden')}
      />
    </AreaContext.Provider>
  );
};
