import { AddDocumentForm } from './add-document.form';
import {
  Banner,
  Button,
  Select,
  TextInput,
  Typography,
  UnstableSelectV2
} from '@chr/eds-react';
import { cancelRequest, cancelSubscription } from 'app/util/util';
import { ChangeEvent, Component, MouseEvent } from 'react';
import { ComponentConnectorFactory } from 'store/component-connector';
import { Container } from 'typedi';
import { ControlledAccordion } from 'shared/components/accordion/controlled-accordion.component';
import { createValidator } from 'app/forms/control/form-validators';
import { Currency } from 'shared/enums/currency.enum';
import { DocumentCategory } from 'shared/enums/document-category.enum';
import { DocumentRepository } from 'app/repositories/document.repository';
import { DocumentsDataTable } from 'pages/payments/payment-tab/pending-review/uploaded-documents-modal/documents-data.table.component';
import { DocumentType as DocType } from 'shared/enums/document-type.enum';
import { EVENT_TYPES } from '@features/analytics/constants';
import { ExtendedLoad, LoadError, LoadNotFound } from 'shared/models/loads/extended-load.model';
import { ExtendedStop } from 'shared/models/loads/stops/extended-stop.model';
import { FormError } from 'shared/components/formatters/form-error.formatter';
import {
  Grid,
  List,
  ListItem,
  Stack,
  Skeleton
} from '@mui/material';
import { hideModal } from 'shared/components/modal/modal.actions';
import { Label } from 'shared/components/elements/label.component';
import { LazyBoundary } from 'shared/components/lazy/lazy-boundary.component';
import { LoadDocument } from 'shared/models/load-document.model';
import { MenuItemProps } from '@chr/eds-react/MenuItem/MenuItem';
import { Modal } from 'shared/components/modal/modal.component';
import { NavCarrierFormControl } from 'app/forms/control/form-field';
import { Observable } from 'rxjs';
import { select } from 'store/selectors/state.selectors';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { Track } from '@chr/react-analytics/models';
import { translate } from 'shared/components/translation/translate';
import { Translation } from 'shared/components/translation/translation.component';
import { usePreferredCurrencyCode } from 'app/hooks/store/use-preferred-currency-code.hook';
import './add-document.component.scss';
import DiscardModal from '@pages/my-loads-v2/modals/discard-modal/discard-modal';


export const ADD_DOCUMENT_MODAL = 'document-upload';
const ONE_MEGA_BYTE = 1048576;

const withHooks = (OriginalComponent: React.ComponentType<Props>) => props => {
  const currencyCode: string = usePreferredCurrencyCode();
  return (<OriginalComponent preferredCurrencyCode={currencyCode} {...props as Props} />);
}

export interface AddDocumentModalContext {
  load: ExtendedLoad;
  onSuccess?: (res: any) => any;
  onError?: (err: any) => any;
}

interface ConnectStateProps {
  resources: Resources;
  context: AddDocumentModalContext;
  visibilityChanges: Subject<boolean>;
  carrierCode: string;
  payableDocket?: PayableDocket;
  preferredCurrencyCode?: string;
}

interface ConnectDispatchProps {
  hideModal: () => any;
}

type Props = ConnectStateProps & ConnectDispatchProps;

type DocumentTypeCollection = { key: string, value: DocType }[];
interface State {
  currencies: ReferenceDataJSON[];
  availableDocumentTypes: DocumentTypeCollection;
  stops: ExtendedStop[];
  files?: FileList;
  documents: AddDocumentForm[];
  uploadedDocuments?: LoadDocument[];
  accordianIsExpanded: boolean;
  isLoading: boolean;
  isDiscardModalOpen: boolean;
}

export class AddDocumentComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const defaultDocument = new AddDocumentForm();
    const docTypeFormControl: NavCarrierFormControl = defaultDocument.get('docType');
    docTypeFormControl.setValue(DocType.POD);

    this.state = {
      currencies: [] as ReferenceDataJSON[],
      availableDocumentTypes: [] as DocumentTypeCollection,
      stops: [] as ExtendedStop[],
      files: null,
      documents: [defaultDocument],
      accordianIsExpanded: false,
      isLoading: false,
      isDiscardModalOpen: false,
    };
  }

  fileInputRef: HTMLInputElement;
  uploadDocumentsEventData: KeyValuePair;
  track = Container.get<Track>('analytics-track');
  documentRepository = Container.get(DocumentRepository);
  refDataRequest: Subscription;
  docTypeRequest: Subscription;
  modalVisibilitySubscription: Subscription;
  formSubscriptions: Subscription[];
  fields: { [key: string]: NavCarrierFormControl };

  UNSAFE_componentWillMount() {
    this.formSubscriptions = this.state.documents.map((doc) => doc.changes.subscribe(() => this.forceUpdate()));
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.refDataRequest = this.documentRepository.getReferenceData('Currency')
      .finally(() => {
        this.setState({ isLoading: false });
      })
      .subscribe(
        data => this.setState({ currencies: this.mapCurrencies(data) }),
        () => this.setState({ currencies: this.mapCurrencies(Currency) })
      );

    const { load } = this.props.context;
    if (!load) {
      return;
    }

    const { visibilityChanges } = this.props;
    this.loadDocumentTypes(load.number);
    this.setState({ stops: load.stops });
    this.subscribeToVisibilityChanges(visibilityChanges);
  }

  componentWillUnmount() {
    cancelRequest(this.refDataRequest);
    cancelRequest(this.docTypeRequest);
    cancelSubscription(this.modalVisibilitySubscription);
    for (let formSubscription of this.formSubscriptions) {
      cancelSubscription(formSubscription);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.visibilityChanges !== nextProps.visibilityChanges) {
      this.subscribeToVisibilityChanges(nextProps.visibilityChanges);
    }
    if (!nextProps.context.load) {
      return;
    }

    if (nextProps.context.load !== this.props.context.load) {
      this.loadDocumentTypes(nextProps.context.load.number);
      this.setState({ stops: nextProps.context.load.stops });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.files !== prevState.files && this.state.files?.length > 0) {
      this.handleFilesUpdate();
    }

    if (this.state.documents !== prevState.documents) {
      for (let formSubscription of this.formSubscriptions) {
        cancelSubscription(formSubscription);
      }

      this.formSubscriptions = this.state.documents
        .map(document => {
          const subscription: Subscription = document.changes.subscribe(() => this.forceUpdate());
          return subscription;
        });
    }


    const categories: DocumentCategory[] = [DocumentCategory.General, DocumentCategory.AccountsReceivable];

    if (!!this.props.payableDocket && this.props.payableDocket !== prevProps.payableDocket) {
      this.setState({ isLoading: true });
      this.documentRepository.getDocumentsPayments(this.props.payableDocket.loadNumber, this.props.payableDocket.loadBook, categories)
        .finally(() => this.setState({ isLoading: false }))
        .subscribe(existingDocuments => this.setState({ uploadedDocuments: existingDocuments }));
    } else if (!!this.props.context?.load && this.props.context.load !== prevProps.context?.load) {

      this.documentRepository.getDocuments(this.props.context.load, categories)
        .finally(() => this.setState({ isLoading: false }))
        .subscribe(existingDocuments => {
          this.setState({ uploadedDocuments: existingDocuments });
        });
    }
  }

  handleDocumentsUpdate() {
    for (let formSubscription of this.formSubscriptions) {
      cancelSubscription(formSubscription);
    }

    this.formSubscriptions = this.state.documents
     .map(document => {
       const subscription: Subscription = document.changes.subscribe(() => this.forceUpdate());
       return subscription;
     });
  }

  handleFilesUpdate() {
    let documentForms: AddDocumentForm[] = [];

    if (this.state.documents?.length === 1 && this.state.files?.length == 1) {
      const documentForm = this.createOrUpdateDocumentForm(this.state.files.item(0), this.state.documents[0]);
      documentForms = [documentForm];
    } else {
      documentForms = Array
        .from(this.state.files)
        .map((file, index) => {
          const documentForm = this.createOrUpdateDocumentForm(file);
          index === 0 && documentForm.get('docType').setValue(DocType.POD);
          return documentForm;
        });
    }

    this.setState({
      documents: documentForms,
    });
  }

  createOrUpdateDocumentForm(file: File, form: AddDocumentForm = new AddDocumentForm()) {
    const isInvalidSize: boolean = this.inValidFileSize(file);
    const fileName = form.get('fileName');
    fileName.setValue(file.name);
    fileName.addValidator(createValidator('isValidFileSize', () => isInvalidSize === false));
    return form;
  }

  onComplete = () => {
    const { documents, files } = this.state;
    const { context, carrierCode, hideModal } = this.props;
    const { load, onSuccess, onError } = context;

    this.setState({ isLoading: true })
    this.uploadDocumentsEventData = {
      user: {
        carrierCode: carrierCode,
      },
      load: {
        number: load.number,
      },
    }

    this.track({ event: EVENT_TYPES.UPLOAD_DOCUMENT, ...this.uploadDocumentsEventData });

    const documentUploadObservables: Observable<void>[] = documents
      .map((document, index) => {
        const formData: FormData = document.generateFormData(files.item(index), load);
        return this.documentRepository.uploadDocument(load, formData);
      });

    Observable.forkJoin(documentUploadObservables)
      .finally(() => this.setState({ isLoading: false }))
      .subscribe(onSuccess, onError);

    this.resetForm();
    hideModal();
  };

  subscribeToVisibilityChanges(visibilityChanges?: Subject<Boolean>) {
    cancelSubscription(this.modalVisibilitySubscription);
    if (visibilityChanges) {
      this.modalVisibilitySubscription = visibilityChanges.subscribe(this.onModalVisibilityChanges);
    }
  }

  onModalVisibilityChanges = (visible: boolean) => {
    if (!visible) {
      this.resetForm();
    }
  };

  loadDocumentTypes(loadNumber: number) {
    this.setState({ isLoading: true })
    this.docTypeRequest = this.documentRepository.getDocumentTypes(loadNumber)
      .finally(() => this.setState({ isLoading: false }))
      .subscribe(docTypes => {
        const types =
          Object.keys(docTypes)
            .map(key => ({ key: key, value: docTypes[key] }))
            .filter(type => type.value !== DocType.Unspecified);

        this.setState({ availableDocumentTypes: types });
      });
  }

  onDocumentTypeChange = (e: any, document: AddDocumentForm) => {
    const { preferredCurrencyCode } = this.props;
    const docType: DocType = e.target.value as DocType;

    document.get('docType').setValue(docType);

    if (docType === DocType.Invoice || docType === DocType.Lumper) {
      const preferredCurrency = Currency[preferredCurrencyCode as keyof typeof Currency];
      const currencyFormControl: NavCarrierFormControl = document.get('currency');
      currencyFormControl.setValue(preferredCurrency);
    }
  };

  onSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const { documents, files } = this.state;

    const isValid = files?.length > 0
      && documents?.length === files.length
      && documents.every(_ => _.valid);

    if (isValid) {
      this.onComplete();
      this.resetForm();
    }
  };

  forceUpdate() {
    super.forceUpdate();
  }

  onCancel = () => {
    this.resetForm();
  };

  onToggleAccordion = (isExpanded: boolean) => {
    const { uploadedDocuments } = this.state;

    if (uploadedDocuments?.length > 0) {
      this.setState({ accordianIsExpanded: isExpanded });
    }
  }

  resetForm = () => {
    if (this.fileInputRef) {
      this.fileInputRef.value = '';
    }

    const defaultDocument = new AddDocumentForm();
    const docTypeFormControl: NavCarrierFormControl = defaultDocument.get('docType');
    docTypeFormControl.setValue(DocType.POD);

    this.setState({
      documents: [defaultDocument],
      files: null,
    });
  };

  inValidFileSize(file: File): boolean {
    const fileSizeLimit = ONE_MEGA_BYTE * 25;
    const fileSize = file?.size || 0;

    return fileSize > fileSizeLimit;
  }

  fileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files: FileList = e.target?.files;
    this.setState({ files: files });
  };

  updateField = (field: NavCarrierFormControl) => (e: any) => {
    field.setValue(e.target.value);
  };

  markAsTouched = (field: NavCarrierFormControl) => () => {
    field.touch();
  };

  formIsValid(): boolean {
    const fileCount: number = this.state.files?.length ?? 0;
    const documentCount: number = this.state.documents?.length ?? 0;

    const isValid: boolean = fileCount > 0
      && documentCount === fileCount
      && this.state.documents.every(document => document.valid);

    return isValid;
  }

  private mapCurrencies(currencyObject) {
    return this.mapRefData(currencyObject).filter(item => item.description.length === 3);
  }

  private mapRefData(refDataObject: ReferenceDataJSON) {
    return Object.keys(refDataObject).map(key => ({ code: refDataObject[key], description: key }));
  }

  private translate(key: ResourceKey): string {
    return translate(this.props.resources, key, []);
  }

  renderInvoiceDocumentFields = (document: AddDocumentForm, documentIndex: number) => {
    const { currencies } = this.state;
    const { currency, invoiceNumber, invoiceAmount, fuelSurcharge } = document.value;

    const invoiceNumberFormControl: NavCarrierFormControl = document.get('invoiceNumber');
    const invoiceAmountFormControl: NavCarrierFormControl = document.get('invoiceAmount');
    const fuelSurchargeFormControl: NavCarrierFormControl = document.get('fuelSurcharge');
    const currencyFormControl: NavCarrierFormControl = document.get('currency');

    const currencyMenuItems = currencies.map(currency => ({ value: currency?.code, label: currency?.description?.toUpperCase() }));

    return (
      <Grid container key={`invoice-document-fields-${documentIndex}`} spacing={1}>
        {invoiceNumberFormControl &&
          <Grid item xs={12}>
            <TextInput
              id={`invoice-number-${documentIndex}`}
              label={this.translate('INVOICE_NUMBER')}
              value={invoiceNumber ?? ''}
              onChange={this.updateField(invoiceNumberFormControl)}
              fullWidth={true}
              required={true}
              error={!invoiceNumberFormControl.valid} />
          </Grid>
        }
        {invoiceAmountFormControl &&
          <Grid item xs={6}>
            <TextInput
              id={`invoice-amount-${documentIndex}`}
              label={this.translate('TOTAL_AMOUNT_INVOICED')}
              inputType='number'
              value={invoiceAmount ?? ''}
              onChange={this.updateField(invoiceAmountFormControl)}
              fullWidth={true} />
          </Grid>
        }
        {fuelSurchargeFormControl &&
          <Grid item xs={4}>
            <TextInput
              id={`fuel-surcharge-${documentIndex}`}
              label={this.translate('FUEL_SURCHARGE')}
              inputType='number'
              value={fuelSurcharge ?? ''}
              onChange={this.updateField(fuelSurchargeFormControl)}
              fullWidth={true} />
          </Grid>
        }
        {currencyFormControl &&
          <Grid item xs={2}>
            <Select
              id={`invoice-currency-${documentIndex}`}
              key={`invoice-currency-${documentIndex}`}
              label={this.translate('CURRENCY')}
              value={currency ?? ''}
              onChange={this.updateField(currencyFormControl)}
              menuItems={currencyMenuItems}
              fullWidth={true} />
          </Grid>
        }
      </Grid>
    );
  }

  renderLumperDocumentFields = (document: AddDocumentForm, documentIndex: number) => {
    const { currencies } = this.state;
    const { lumperAmount, currency } = document.value;

    const lumperAmountFormControl: NavCarrierFormControl = document.get('lumperAmount');
    const lumperCurrencyFormControl: NavCarrierFormControl = document.get('currency');

    const currencyMenuItems: MenuItemProps[] = currencies
      .map(currency => ({ value: currency?.code, label: currency?.description?.toUpperCase() } as MenuItemProps));

    return (
      <Grid container spacing={1} key={`lumper-document-${documentIndex}`} justifyContent='flex' >
        {lumperAmountFormControl &&
          <Grid item xs='auto'>
            <TextInput
              id={`lumper-amount-${documentIndex}`}
              label={this.translate('AMOUNT')}
              inputType='number'
              value={lumperAmount ?? ''}
              onChange={this.updateField(lumperAmountFormControl)}
              fullWidth={true}
              required={true}
              error={!(lumperAmount > 0)} />
          </Grid>
        }
        {lumperCurrencyFormControl &&
          <Grid item xs={2}>
            <Select
              id={`lumper-currency-${documentIndex}`}
              key={`lumper-currency-${documentIndex}`}
              label={this.translate('CURRENCY')}
              value={currency ?? ''}
              onChange={this.updateField(lumperCurrencyFormControl)}
              menuItems={currencyMenuItems}
              fullWidth={true} />
          </Grid>
        }
      </Grid>
    );
  }

  renderDocumentUploadRow = (document: AddDocumentForm, documentIndex: number) => {
    const { availableDocumentTypes, stops, files } = this.state;
    const { docType, stop } = document.value;

    const filenameFormControl: NavCarrierFormControl = document.get('fileName');
    const stopFormControl: NavCarrierFormControl = document.get('stop');
    const docTypeFormControl: NavCarrierFormControl = document.get('docType');

    const filename: JSX.Element = !!filenameFormControl?.value
      ? <>{filenameFormControl.value}</>
      : <Translation resource="NO_FILE_SELECTED" />;

    const isOdometerSelected: boolean = Number(docTypeFormControl.value) === DocType.Odometer;
    const isStopSet: boolean = typeof stopFormControl.value === 'number' && stopFormControl.value >= 0;

    const isLumperDoc: boolean = docType === DocType.Lumper;
    const isInvoiceDoc: boolean = docType === DocType.Invoice;

    const stopOptions = stops.map(stop => ({
      value: stop.sequenceNumber,
      label: `${(stop.isPickup() ? 'P-' : 'D-')}${stop.name}`,
    } as MenuItemProps));

    const documentTypeOptions = availableDocumentTypes
      .map(docType => ({
        value: docType.value,
        label: docType.key?.toUpperCase(),
      } as MenuItemProps));

    const hasFilesSelected = files?.length > 0;

    return (
      <ListItem key={`document-upload-row-${documentIndex}`}>
        <Grid container spacing={1} justifyContent={'flex-end'}>
          <Grid item xs={4}>
            <Typography variant={'h5'}>{filename}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Stack direction={'row'} spacing={1} justifyContent={'flex'}>
              <UnstableSelectV2
                id={`document-type-${documentIndex}`}
                key={`document-type-${documentIndex}`}
                label={this.translate('DOCUMENT_TYPE')}
                disableDeselect={true}
                value={docType ?? ''}
                onChange={_ => this.onDocumentTypeChange(_, document)}
                menuItems={documentTypeOptions}
                required={true}
                error={!docTypeFormControl.valid} />
              <Select
                id={`stop-list-${documentIndex}`}
                key={`stop-list-${documentIndex}`}
                label={this.translate('STOP')}
                value={stop ?? ''}
                onChange={this.updateField(stopFormControl)}
                menuItems={[{
                  value: null,
                  label: this.translate('NONE'),
                }, ...stopOptions]}
                required={isOdometerSelected}
                error={isOdometerSelected && !isStopSet}
                disabled={!hasFilesSelected} />
            </Stack>
            {isLumperDoc && this.renderLumperDocumentFields(document, documentIndex)}
            {isInvoiceDoc && this.renderInvoiceDocumentFields(document, documentIndex)}
          </Grid>
        </Grid>
      </ListItem>
    );
  }

  renderDocumentUploadList = () => {
    const { documents, availableDocumentTypes } = this.state;

    const isReady = availableDocumentTypes?.length > 0;

    return isReady && (
      <LazyBoundary>
        <List>
          {documents.map((document, index) => this.renderDocumentUploadRow(document, index))}
        </List>
      </LazyBoundary>
    );
  }

  renderFileUploadContainer = () => {
    return (
      <div className="file-upload-container">
        <div>
          <Button
            key="btn-choose-file"
            variant='contained'
            buttonText={this.translate('CHOOSE_FILE')}
            onClick={() => this.fileInputRef.click()}
          />
          <input
            type="file"
            multiple={true}
            id="document-field"
            accept=".pdf,.tif,.tiff,.jpg,.jpeg"
            ref={ref => this.fileInputRef = ref}
            onChange={this.fileChange}
          />
          <Label
            required={true}
            htmlFor="document-field" />
        </div>
      </div>
    );
  }

  showDiscardModal = () => {
    this.setState({isDiscardModalOpen: true});
  }

  onDiscard = () => {
    this.setState({documents: []});
    this.props.hideModal();
  }

  handleDiscardModal = () => {
    if (this.state.documents?.length > 0 && this.state.files?.length > 0){
      this.showDiscardModal();
    }else{
      this.props.hideModal();
    }
  }
  renderModalButtons = () => {
    return (
      <div className='modal-footer button-bay_mobile-responsive text-right'>
        <Button
          key="cancel"
          variant='outlined'
          id="btn-cancel-addDocument"
          onClick={() => this.handleDiscardModal()}
          buttonText={this.translate('CANCEL')}
        />
        <Button
          key="add"
          variant='contained'
          id="btn-addDocument"
          onClick={this.onSubmit}
          disabled={!this.formIsValid()}
          buttonText={this.translate('UPLOAD')}
        />
      </div>
    );
  }

  renderForm = (context: AddDocumentModalContext) => {
    const load = context.load;
    const {
      documents,
      files,
    } = this.state;

    if (load?.number == null) {
      return null;
    }

    if (load instanceof LoadNotFound || load instanceof LoadError) {
      return (
        <div className="chr-document-upload">
          <p><Translation resource="AN_UNKNOWN_ERROR_OCCURRED_TRYING_TO_MAKE_THE_REQUE" /></p>
          <p><Translation resource="PLEASE_REFRESH_THIS_WINDOW_OR_CLOSE_THIS_WINDOW_AN" /></p>
        </div>
      );
    }

    const hasFileNameError: boolean = documents?.some(document => document.get('fileName').hasError('pattern')) == true;
    const hasFileSizeError: boolean = documents?.some(document => document.get('fileName').hasError('isValidFileSize')) == true;
    const hasFiles: boolean = files?.length > 0;

    return (
      <>
        {this.state.isLoading ?
          (<Skeleton variant="rectangular" animation="wave">
            {this.renderDocumentUploadForm(load, hasFileNameError, hasFileSizeError, hasFiles)}
          </Skeleton>) :
          (
            this.renderDocumentUploadForm(load, hasFileNameError, hasFileSizeError, hasFiles)
          )}
      </>
    );
  }

  renderDocumentUploadForm = (
    load: ExtendedLoad,
    hasFileNameError: boolean,
    hasFileSizeError: boolean,
    hasFiles: boolean) => {
    const {
      accordianIsExpanded,
      uploadedDocuments
    } = this.state;

    return (
      <div className="chr-document-upload">
        {this.renderDocumentUploadList()}
        {this.renderFileUploadContainer()}
        <hr />
        <ul className="space-outer-top-md">
          <li>
            <span><Translation resource="FOR" /></span>
            <span className="bold-text"><Translation resource="TRUCK_ORDER_NOT_USED" /></span>
            <span><Translation resource="LOADS_DASH" /></span>
            <span className="bold-text"><Translation resource="INVOICE" /></span>
            <span><Translation resource="UPLOAD_IS_REQUIRED" /></span>
          </li>
          <li><Translation resource="PROCESSING_TIME_BEGINS_ONCE_WE_RECEIVE_YOUR_FILES_" /></li>
          <li><Translation resource="DOCUMENTS_RECEIVED_AFTER_12PM_CST_WILL_BE_PROCESSE" /></li>
          <li><Translation resource="DOCUMENTS_ARE_NOT_PROCESSED_ON_WEEKENDS_OR_HOLIDAY" /></li>
        </ul>
        <div className="space-outer-top-lg">
          {hasFileNameError &&
            <div className="space-outer-top-lg">
              <FormError><Translation resource="IMAGE_MUST_BE_OF" /></FormError>
            </div>
          }
          {hasFileSizeError &&
            <div className="space-outer-top-lg">
              <FormError><Translation resource="FILE_EXCEEDS_THE_MAXIMUM_FILE_SIZE_OF_25MB" /></FormError>
            </div>
          }
        </div>
        {hasFiles &&
          <Banner
            severity='info'
            title={this.translate('DOCUMENT_UPLOAD_WARN_TITLE')}
            description={this.translate('DOCUMENT_UPLOAD_WARN_DESCRIPTION')} />
        }
        {this.renderModalButtons()}
        <ControlledAccordion
          labelKey="UPLOADED_DOCUMENTS"
          isExpanded={accordianIsExpanded}
          onToggleAccordion={this.onToggleAccordion}
          renderHeader={this.renderAccordionHeader}
        >
          <DocumentsDataTable
            documents={uploadedDocuments}
            loadNumber={load.number} />
        </ControlledAccordion>
      </div>
    );
  }

  renderAccordionHeader = () => {
    const { uploadedDocuments, availableDocumentTypes } = this.state;
    const hasUploadedDocuments: boolean = uploadedDocuments?.length > 0;

    const documentTypeCounts: { [key: number]: number } = uploadedDocuments
      ?.reduce((accumulator, current) => {
        const docType = current.type;
        if (!accumulator[docType]) {
          accumulator[docType] = 1;
        } else {
          accumulator[docType] += 1;
        }
        return accumulator;
      }, {});

    const documentCountLine: string = availableDocumentTypes
      .filter(_ => documentTypeCounts?.[_.value] > 0)
      .map(_ => {
        const isPlural: boolean = documentTypeCounts[_.value] > 1;
        const documentType: string = _.key.toUpperCase();
        const documentCount: number = documentTypeCounts[_.value];

        return `${documentType}${isPlural ? 's' : ''}: ${documentCount}`;
      })
      .join(' ');

    return (
      <>
        {!hasUploadedDocuments && <h5> <Translation resource='UPLOADED_DOCUMENTS' />: <Translation resource='NONE' /> </h5>}
        {hasUploadedDocuments && <h5><Translation resource='UPLOADED_DOCUMENTS' />: {documentCountLine}</h5>}
      </>
    );
  }

  render(): JSX.Element {
    return (
     <>
        <Modal
          name={ADD_DOCUMENT_MODAL}
          isForm
          header="UPLOAD_DOCUMENT"
          children={this.renderForm}
          disableClickingOffModalClose={true}
          handleDiscardModal={() => this.handleDiscardModal()}
        />
       <DiscardModal onDiscard={this.onDiscard} isOpen={this.state.isDiscardModalOpen} onClose={() => {this.setState({isDiscardModalOpen: false}); }} />
     </>
    );
  }
}

export const AddDocument = ComponentConnectorFactory<{}, ConnectStateProps, ConnectDispatchProps>()
  .combineStateSelectors(
    select.resources,
    select.auth.user.carrierCode,
    select.modals.select((state) => ({ visibilityChanges: state[ADD_DOCUMENT_MODAL] ? state[ADD_DOCUMENT_MODAL].visibilityChanges : new Subject<boolean>() })),
    select.modals.select((state) => ({ context: state[ADD_DOCUMENT_MODAL] ? state[ADD_DOCUMENT_MODAL].context as AddDocumentModalContext : {} as any }))
  )
  .mapDispatchToProps({
    hideModal: () => hideModal(ADD_DOCUMENT_MODAL)
  })
  .connect(withHooks(AddDocumentComponent));
