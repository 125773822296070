import { DateTimeFormatter, FORMATS } from 'shared/components/formatters/date-time.formatter';
import { Translation } from 'shared/components/translation/translation.component';
import { DocumentType } from 'shared/components/formatters/document-type.formatter';
import { DocumentStatus } from 'shared/components/formatters/document-status.formatter';
import { LoadDocument } from 'shared/models/load-document.model';
import { Row, Cell } from 'shared/components/data-table/data-table.lazy';
import { Button } from 'shared/components/elements/elements.components';

interface Props {
  document: LoadDocument;
  loadNumber: number;
  onDownload?: (document: LoadDocument) => any;
  columns: number;
}

export const DocumentRow = ({ document, columns, loadNumber, ...props }: Props) => {
  const documentName: string = `${loadNumber}-${document.id}${document.fileExtension}`;

  return (
    <Row columns={columns}>
      <Cell><DocumentType value={document.type} code={document.code} /></Cell>
      <Cell>{documentName}</Cell>
      <Cell><DocumentStatus status={document.status} /></Cell>
      {document.canDownload() &&
        <Cell className="hidden-print">
          {Boolean(document.id) &&
            <Button
              btnLink className="js-btn-download-document hidden-print"
              id={`document-${document.id}`} onClick={() => props.onDownload(document)}
              resource="DOWNLOAD"
            >
              <img src="/assets/images/download.svg" className="download-btn-icon" width="14px" height="14px"/>
            </Button>}
        </Cell>
      }
      {document.isProcessingUpload() &&
        <Cell className="hidden-print"><Translation resource="PROCESSING" /></Cell>}

      {!document.isProcessingUpload() && !document.canDownload() &&
        <Cell className="hidden-print" />}
      <Cell><DateTimeFormatter date={document.createdDateTime} format={FORMATS.DateAndTime} hasSeconds={false} /></Cell>
    </Row>
  );
}

