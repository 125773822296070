import { InformationalDialog } from '@chr/eds-react';
import { useTranslation } from 'react-i18next';

interface DiscardModalProps {
 onDiscard: () => void;
 isOpen: boolean;
 onClose: () => void;
}

const DiscardModal = ({onDiscard, isOpen, onClose}: DiscardModalProps) => {
 const {t} = useTranslation();
 return(
  <InformationalDialog
    onClose={onClose}
    open={isOpen}
    id="discard-modal"
    title={t('ARE_YOU_SURE_YOU_WANT_LEAVE')}
    primaryAction={{
     buttonText: t('DISCARD'),
     id: 'btn-discard',
     onClick: () =>  {
      onDiscard();
      onClose();
      },
     'data-testid': 'btn-discard'
    }}
    secondaryAction={{
     buttonText: t('CANCEL'),
     id: 'btn-cancel',
     'data-testid': 'btn-cancel',
     onClick: () => {
      onClose();
     },
    }}
   bodyText={t('DISCARD_INFORMATION')}/>
 );
};

export default DiscardModal;
