/// Used when submitting an Invoice Amount or Lumper Amount on the Website ONLY.
/// Defaults to USD if no currency has been selected.
export enum Currency {
    /// U.S. Dollar
    USD = 0,
    /// United Arab Emirates Dirham
    AED = 1,
    /// Argentine Peso
    ARS = 2,
    /// Australian Dollar
    AUD = 3,
    /// Barbadian Dollar
    BBD = 4,
    /// Bahraini Dinar
    BHD = 5,
    /// Bermudan Dollar
    BMD = 6,
    /// Brazilian Real
    BRL = 7,
    /// Bahamian Dollar
    BSD = 8,
    /// Canadian Dollar
    CAD = 9,
    /// Swiss Franc
    CHF = 10,
    /// Chilean Peso
    CLP = 11,
    /// Chinese Yuan
    CNY = 12,
    /// Colombian Peso
    COP = 13,
    /// Costa Rican colón
    CRC = 14,
    /// Czech Koruna
    CZK = 15,
    /// Danish Krone
    DKK = 16,
    /// Euro
    EUR = 17,
    /// Fijian Dollar
    FJD = 18,
    /// British Pound
    GBP = 19,
    /// Hong Kong Dollar
    HKD = 20,
    /// Hungarian Forint
    HUF = 21,
    /// Israeli New Shekel
    ILS = 22,
    /// Indian Rupee
    INR = 23,
    /// Icelandic Króna
    ISK = 24,
    /// Japanese Yen
    JPY = 25,
    /// Kenyan Shilling
    KES = 26,
    /// South Korean Won
    KRW = 27,
    /// Kuwaiti Dinar
    KWD = 28,
    /// Sri Lankan Rupee
    LKR = 29,
    /// Mexican Peso
    MXN = 30,
    /// Malaysian Ringgit
    MYR = 31,
    /// Norwegian Krone
    NOK = 32,
    /// New Zealand Dollar
    NZD = 33,
    /// Omani Rial
    OMR = 34,
    /// Peruvian Sol
    PEN = 35,
    /// Philippine Peso
    PHP = 36,
    /// Pakistani Rupee
    PKR = 37,
    /// Polish Zloty
    PLN = 38,
    /// Qatari Rial
    QAR = 39,
    /// Romanian Leu
    RON = 40,
    /// Russian Ruble
    RUB = 41,
    /// Saudi Riyal
    SAR = 42,
    /// Swedish Krona
    SEK = 43,
    /// Singapore Dollar
    SGD = 44,
    /// Thai Baht
    THB = 45,
    /// Turkish Lira
    TRY = 46,
    /// Trinidad & Tobago Dollar
    TTD = 47,
    /// New Taiwan Dollar
    TWD = 48,
    /// Ukrainian Hryvnia
    UAH = 49,
    /// Uruguayan Peso
    UYU = 50,
    /// Venezuelan Bolívar
    VEB = 51,
    /// Venezuelan Bolívar
    VEF = 52,
    /// Vietnamese Dong
    VND = 53,
    /// CFP Franc
    XPF = 54,
    /// South African Rand
    ZAR = 55
}
