import React from 'react';

import { Option } from 'shared/components/elements/elements.components';
import { DocumentType as DocType } from 'shared/enums/document-type.enum';
import { Translation } from 'shared/components/translation/translation.component';
import { useResources } from 'app/hooks/store/use-resources.hook';

const DocumentTypeMap = {
  Accessorial: 'ACCESSORIAL',
  BOL: 'BOL',
  Invoice: 'INVOICE',
  Lumper: 'LUMPER',
  POD: 'POD',
  WeightTicket: 'WEIGHT_TICKET',
  Other: 'OTHER',
  Unspecified: 'OTHER',
  Odometer: 'ODOMETER',
  InternationalCMR: 'INTERNATIONAL_CMR',
  InternationalPOD: 'INTERNATIONAL_POD',
  Thermoscript: 'Thermoscript', // No translation
  Pallets: 'PALLETS',
  PackingList: 'PACKING_LIST',
  Customs: 'CUSTOMS',
  CleaningCertificate: 'CLEANING_CERTIFICATE',
  CONFIRM: 'RATE_CONFIRMATION',
  PICKUP: 'PICKUP',
  DELIVERY: 'DELIVERY_PRENOTE',
  CARNOTIF: 'DOC_NOTIFICATION',
  COMM_INV: 'COMMERCIAL_INVOICE',
  CUST_OTHER: 'DOC_CUSTOMER',
  TENDER: 'DOC_TENDER',
  STATACCT: 'STATEMENT_OF_ACCOUNT',
  SACARINV: 'INVOICE',
  CUSTRATEAG: 'RATE',
  AWB: 'AIR_WAY_BILL',
  TMC_OTHER: 'TMC_OTHER'
};

interface DocumentTypeProps {
  value: DocType;
  code?: string;
}

export const DocumentType = ({value, code}: DocumentTypeProps) => {
  const resourceKey = DocumentTypeMap[DocType[value]] || DocType[value];

  if (resourceKey === 'OTHER' && code) {
    return (
      <React.Fragment>
        <Translation resource={resourceKey}/> ({code})
      </React.Fragment>
    );
  }

  return (
    <Translation resource={resourceKey}/>
  );
};

interface DocumentTypeOptionProps {
  docType: { key: string, value: DocType };

  [key: string]: any;
}

export const DocumentTypeOption = ({docType, value}: DocumentTypeOptionProps) => {
  const resources = useResources();
  if (!docType) {
    return null;
  }
  const resource = DocumentTypeMap[DocType[docType.value]] || DocType[docType.value];
  return resources[resource]
    ? <Option value={value} resource={resource}/>
    : <option value={value}>{docType.key?.toUpperCase()}</option>;
};
